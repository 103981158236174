<template>
  <div v-if="!loading" class="product-detail">
    <!-- <div class="d-flex justify-content-start align-items-center mb-3">
      <div>
        <a-icon @click.prevent="() => $router.go(-1)" type="arrow-left"
          style="font-size: 20px; color: #000000; cursor: pointer" />
      </div>
      <div class="mx-3 catalog-icon">
        <DetailIcon />
      </div>
      <div class="">
        <h3 class="title-detail"> {{ $t('purchase.catalogue.product_detail') }} </h3>
      </div>
    </div> -->

    <div class="wrapper__product__detail" v-if="product">
      <a-row type="flex" :gutter="[32, 8]">
        <a-col :xs="{ span: 24, order: 1 }" :sm="{ span: 24, order: 1 }" :md="{ span: 12, order: 1 }">
          <div v-if="isMobileView" class="mb-2">
            <a-breadcrumb>
              <a-breadcrumb-item href>
                <router-link to="/">
                  <a-icon type="home" />
                </router-link>
              </a-breadcrumb-item>
              <a-breadcrumb-item>{{ getCategory?.name }}</a-breadcrumb-item>
              <a-breadcrumb-item>{{ product.title }}</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
          <a-row v-if="!isMobileView">
            <a-col :span="6">
              <a-row
                :span="24"
                class="d-flex justify-content-end align-items-center flex-wrap mt-3"
                :style="isExpandImage ? 'overflow: scroll; height: 400px' : ''"
              >
                <template v-if="showListImage?.length > 0">
                  <img v-for="(image, index) in showListImage" :key="index" @click="setShowImage(image)"
                    class="img-thumbnail mb-2 mx-2" :src="`${image.url}`"
                    style="width: 90px; height: 90px; cursor: pointer; object-fit: contain;"
                  />
                </template>
                <template v-else>
                  <img @click="setShowImage(image)"
                    class="img-thumbnail mb-2 mx-2" :src="'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'"
                    style="width: 90px; height: 90px; cursor: pointer; object-fit: contain;"
                  />
                </template>
              </a-row>
            </a-col>
            <a-col :span="18">
              <a-col :span="24" class="d-flex justify-content-center align-items-center"
                v-if="!!showImage || (product && product.all_images)">
                <inner-image-zoom :src="`${!!showImage ? showImage.url : product.all_images[0] ? product.all_images[0].url : 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'}`" :hideHint="true" class="img-zoom-v2" />
              </a-col>

              <a-col :span="24" class="d-flex justify-content-center align-items-center mt-3" v-if="haveButtonAllImages">
                <div class="button-expand-image text-center" @click.prevent="handleButtonAllImages">
                  {{ isExpandImage ? $t('lessButtonCaption'): $t('expandButtonCaption') }}
                </div>
              </a-col>
            </a-col>
          </a-row>
          <a-row v-else>
            <a-col :span="24" class="d-flex justify-content-center align-items-center"
              v-if="!!showImage || (product && product.all_images)">
              <inner-image-zoom :src="`${!!showImage ? showImage.url : product.all_images[0].url}`" :hideHint="true" class="img-zoom" />
            </a-col>

            <a-col :span="24" class="d-flex justify-content-center align-items-center flex-wrap mt-3">
              <img v-for="(image, index) in showListImage" :key="index" @click="setShowImage(image)"
                class="img-thumbnail mb-2 mx-2" :src="`${image.url}`"
                style="width: 90px; height: 90px; cursor: pointer; object-fit: contain;" />
            </a-col>

            <a-col :span="24" class="d-flex justify-content-center align-items-center mt-3" v-if="haveButtonAllImages">
              <div class="button-expand-image text-center" @click.prevent="handleButtonAllImages">
                {{ isExpandImage? $t('lessButtonCaption'): $t('expandButtonCaption') }}
              </div>
            </a-col>
          </a-row>
        </a-col>

        <a-col :xs="{ span: 24, order: 2 }" :sm="{ span: 24, order: 2 }" :md="{ span: 12, order: 2 }">
          <a-row>
            <div v-if="!isMobileView" class="mb-1">
              <a-breadcrumb>
                <a-breadcrumb-item href>
                  <router-link to="/">
                    <a-icon type="home" />
                  </router-link>
                </a-breadcrumb-item>
              <a-breadcrumb-item>{{ getCategory?.name }}</a-breadcrumb-item>
                <a-breadcrumb-item>{{ product.title }}</a-breadcrumb-item>
              </a-breadcrumb>
            </div>
            <a-row>
              <a-col :span="24" class="mt-5 mb-2 mt-md-0 title">
                <div>{{ product.title }}</div>
              </a-col>
            </a-row>

            <!-- <a-col :span="24" class="mt-2 mb-3 price" v-if="unitSelected">
              {{ unitSelected.selling_price | currency }}
            </a-col> -->

            <!-- <a-divider /> -->

            <a-row>
              <a-col :span="24" class="mb-2" v-if="product && product.catalogs">
                <a-col :xs="24" :sm="24" :md="12" class="sku d-flex align-items-center">
                  <div class="mr-2"> SKU : </div>
                  <div> {{ selectedVariant.sku }} </div>
                </a-col>
              </a-col>
            </a-row>
            <a-row v-if="unitSelected">
              <a-col
                :span="isMobileView ? 6 : 4"
                class="price-disc-1 mt-2"
                v-if="getDiscountLabel(product)?.length > 0"
              >
                {{ unitSelected && unitSelected.selling_price | currency }}
              </a-col>
              <a-col :span="4" class="label-disc-2 mt-2" v-if="getDiscountLabel(product)?.length > 0 && !unitSelected.percentage_price_sales">
                {{ getDiscountLabel(product)?.[0]?.promotions?.[0]?.gift?.[0]?.value_type === 'fixed' ? `Rp ${toCurrency(getDiscountLabel(product)?.[0]?.promotions?.[0]?.gift?.[0]?.value)} OFF` : `${getDiscountLabel(product)?.[0]?.promotions?.[0]?.gift?.[0]?.value}% OFF` }}
              </a-col>
              <a-col
                :span="isMobileView ? 6 : 4"
                class="price-disc-2-v2 mt-2"
                v-if="getDiscountLabel(product)?.length > 0 && unitSelected.percentage_price_sales"
              >
                {{ unitSelected && calculatePrice(unitSelected.calc_price_sales_discount, unitSelected.percentage_price_sales, unitSelected.selling_price,  getDiscountLabel(product)?.[0]?.promotions?.[0]?.gift?.[0]?.value, getDiscountLabel(product)?.[0]?.promotions?.[0]?.gift?.[0]) | currency }}
              </a-col>
              <a-col :span="isMobileView ? 4 : 2" class="price-disc-3 ml-3 mt-2" v-if="unitSelected.percentage_price_sales">
                +{{ unitSelected.percentage_price_sales }}% OFF
              </a-col>
            </a-row>
            <a-row>
              <a-col :xs="10" :sm="10" :md="9" style="width :-webkit-fill-available" class="d-flex align-items-center">
                <div v-if="unitSelected" class="price d-flex">
                  <div class="mt-2">
                    {{ unitSelected && calculatePrice(unitSelected.calc_price_sales_discount, unitSelected.percentage_price_sales, unitSelected.selling_price, getDiscountLabel(product)?.[0]?.promotions?.[0]?.gift?.[0]?.value, getDiscountLabel(product)?.[0]?.promotions?.[0]?.gift?.[0]) | currency }}
                  </div>
                </div>
              </a-col>
            </a-row>

            <a-divider v-if="variants.length > 1"  />

            <a-col :span="24" class="mb-3 variant" v-if="variants && variants.length > 1">
              <div class="d-flex justify-content-start align-items-center flex-wrap">
                <div class="variant_title mr-4"> {{ variants[0].varian }} </div>
                <a v-for="(item, index) in variants" :key="index"
                  class="variant_option my-2 mr-2 d-flex justify-content-center align-items-center"
                  @click.prevent="() => handleChangeVariant(item.catalog_id, item)"
                  :class="{ active: selectedVariant && selectedVariant.id === item.catalog_id }">
                  <div>
                    {{ item.title }}
                  </div>
                </a>
              </div>
            </a-col>

            <a-divider/>

            <div class="mb-3 mt-3" v-if="listWarehouse?.length > 1">
              <div class="choose_variant mb-2"> Dikirim Dari</div>
              <a-select v-model="selectedWarehouse" size="large" show-search placeholder="Pilih gudang" class="w-100">
                <a-select-option v-for="(item, index) in listWarehouse" :key="index" :value="item.id">
                  {{ `${item?.name || ''} - ${item?.address?.city || ''}` }}
                </a-select-option>
              </a-select>
            </div>
            <div class="mb-3 mt-4" v-else>
              <div class="choose_variant">Dikirim Dari</div>
              <div class="sku mb-3">{{ `${listWarehouse[0]?.name || ''} - ${listWarehouse[0]?.address?.city || ''}` }}</div>
            </div>

            <a-row v-if="selectedWarehouse">
              <a-col :span="24" class="mb-2" v-if="product && product.catalogs">
                <a-col :xs="24" :sm="24" :md="12" class="sku d-flex align-items-center">
                  <div class="mr-2"> Stok : </div>
                  <div v-if="isPreorder" style="color: var(--biz-accent-success-toast)"> {{ $t('purchase.catalogue.preorderStock') }} </div>
                  <div v-else-if="stock" style="color: var(--biz-accent-success-toast)"> {{ $t('purchase.catalogue.inStock') }} </div>
                  <div v-else style="color: var(--biz-accent-failed-toast)"> {{ $t('purchase.catalogue.outOfStock') }} </div>
                </a-col>
              </a-col>
            </a-row>
            <a-row v-if="selectedWarehouse">
              <template v-if="unitList?.length > 0">
                <a-col :span="6" class="d-flex justify-content-center align-items-center mr-2">
                  <a-row>
                    <div class="select-box d-flex justify-content-between align-items-center">
                      <div @click.prevent="updateQty('min')" style="cursor: pointer"> - </div>
                      <div>
                        <a-input
                          class="centered-input input-add-cart"
                          :value="qty"
                          @keypress="validateQty($event)"
                          @input="updateQty"
                          @blur="handleNullQty"
                        />
                      </div>
                      <div @click.prevent="updateQty('add')" style="cursor: pointer"> + </div>
                    </div>
                  </a-row>
                </a-col>
                <a-col :span="6" class="mr-2">
                  <a-row>
                    <div class="select-box" style="cursor: pointer">
                      <a-dropdown :trigger="['click']">
                        <div class="d-flex justify-content-between align-items-center">
                          <div> {{ unitSelected ? `${unitSelected.uom}` : $t('purchase.catalogue.select_unit') }} </div>
                          <div> <a-icon type="down" /> </div>
                        </div>
                        <a-menu slot="overlay" @click="handleSelectUom">
                          <a-menu-item v-for="(item) in unitList" :key="item.uom" class="row">
                            <div class="col-24 d-flex align-items-center" style="gap: 10px;">
                              <div>{{ item.uom }}</div>
                              <div>{{ item?.selling_price ? '-' : '' }}</div>
                              <div v-if="getDiscountLabel(product)?.length > 0 ">
                                <div style="text-decoration: line-through; color: #a7a9c9; font-size: 12px;">
                                  {{ isLoyalty ? '' : 'Rp' }}
                                  {{ toCurrency(item.selling_price) }}
                                </div>
                                <div>
                                  {{ isLoyalty ? '' : 'Rp' }}
                                  {{
                                    toCurrency(calculatePrice(
                                      item.calc_price_sales_discount,
                                      item.percentage_price_sales,
                                      item.selling_price,
                                      getDiscountLabel(product)?.[0]?.promotions?.[0]?.gift?.[0]?.value,
                                      getDiscountLabel(product)?.[0]?.promotions?.[0]?.gift?.[0]
                                    ))
                                  }}
                                </div>
                              </div>
                              <div v-else>
                                {{ isLoyalty ? '' : 'Rp' }}
                                {{ toCurrency(item.selling_price) }}
                              </div>
                            </div>
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </div>
                  </a-row>
                </a-col>
                <a-col class="d-flex">
                  <a-button
                    :loading="isLoading"
                    @click="handleAddToCart"
                    type="primary"
                    :class="stock || isPreorder ? 'btn-submit d-flex align-items-center w-100 justify-content-center' : 'btn-submit-disabled d-flex align-items-center w-100 justify-content-center'"
                    :disabled="!stock && !isPreorder"
                  >
                      <bag class="mr-1" /> {{ $t('purchase.catalogue.addCart') }}
                    </a-button>
                </a-col>
              </template>
              <div v-else>
                <div class="py-2 px-3 d-flex align-items-center mt-4"
                  style="background-color: #F4F4F4; border-radius: 12px; width: fit-content;">
                  <Information :color="'#000'" />
                  <span class="ml-2" style="color: #000;">
                    {{ $t('purchase.catalogue.view_only') }}
                    <router-link class="mb-3" :to="{ path: `/page/customer-service` }">
                      <span class="nama_kategori" style="cursor: pointer">customer services</span>
                    </router-link>
                  </span>
                </div>
              </div>
            </a-row>

            <div v-if="isMobileView" class="mt-4" style="text-align: center">
              <a-tabs class="pca-tab-store" :default-active-key="defaultTabActive" @change="handleChangeTab">
                <a-tab-pane :key="1" tab="Deskripsi" />
                <a-tab-pane disabled :key="2" tab="Brand" />
                <a-tab-pane disabled :key="3" tab="Informasi" />
              </a-tabs>
            </div>

            <a-col  :span="24" class="mt-4 mb-3" v-if="product && product.description && isMobileView">
              <!-- <div class="mb-3" style="
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 16px;
                  letter-spacing: 0.05em;
                  color: #000000;
                ">
                {{ $t('purchase.catalogue.product_description') }}
              </div> -->

              <!-- <div class="my-1 desc-product"> {{ product.description.short_description }} </div> -->
              <div :class="showDescription ? 'desc-product' : '--h___200 desc-product'" v-html="product.description.long_description" />
              <div class="btn-show d-flex align-items-center gap-2" @click="handleShowDescription">{{ showDescription ? 'Lihat Lebih Ringkas' : 'Lihat Selengkapnya'}}<i v-if="showDescription" class="fe fe-arrow-up" /></div>
            </a-col>

            <!-- <a-divider /> -->

            <!-- <a-divider v-if="variants && variants.length > 1" />
            <a-col :md="15" class="d-flex justify-content-end" style="width :-webkit-fill-available">
              <a-button @click="backCatalogue" class="mr-3 btn-back" type="primary" ghost>Kembali</a-button>
              <a-button @click="visible = true" type="primary" class="btn-submit">+ Keranjang</a-button>
            </a-col> -->

          </a-row>
        </a-col>
      </a-row>

      <div v-if="!isMobileView" class="mt-4" style="text-align: center">
        <a-tabs class="pca-tab-store" :default-active-key="defaultTabActive" @change="handleChangeTab">
          <a-tab-pane :key="1" tab="Deskripsi" />
          <a-tab-pane disabled :key="2" tab="Brand" />
          <a-tab-pane disabled :key="3" tab="Informasi" />
        </a-tabs>
      </div>

      <a-row class="mt-5" v-if="!isMobileView">
        <a-col :span="24" class="mt-4 mb-3" v-if="product && product.description">
          <div :class="showDescription ? 'desc-product' : '--h___200 desc-product'" v-html="product.description.long_description" />
          <div class="btn-show d-flex align-items-center gap-2" @click="handleShowDescription">{{ showDescription ? 'Lihat Lebih Ringkas' : 'Lihat Selengkapnya'}}<i v-if="showDescription" class="fe fe-arrow-up" /></div>
        </a-col>
      </a-row>

      <div class="d-flex justify-content-center align-items-center mt-5 mb-5" v-if="listProductRecomendation.length > 0">
        <div class="ml-2">
          <h3> {{ $t('purchase.catalogue.related') }} </h3>
        </div>
      </div>
       <div class="product-wrapper" v-if="listProductRecomendation.length > 0">
        <div class="product-content d-flex flex-wrap">
          <div
            v-for="(product, index) in listProductRecomendation"
            :key="index"
            class="product-list mb-3 mx-4"
          >
            <CatalogueListItem
              :product="product"
              :prices="priceRecomendation"
              :list_product="listProductRecomendation"
              :dataDiscount="recomendationDataDiscount"
            />
          </div>
        </div>
      </div>
      <!-- <b-modal id="modal-response" hide-footer header-class="border-0" v-model="visible" centered>
        <template>
          <a-row>
            <a-col :xs="24" :sm="24" :md="12" class="d-flex justify-content-between align-items-center"
              style="width : -webkit-fill-available">
              <span style="color : rgb(0, 0, 0); font-weight : bold;">{{ product.title }}</span>
            </a-col>
          </a-row>

          <a-divider />

          <a-col :span="24" class="mt-2 mb-3" v-if="product && product.catalogs">
            <a-row>
              <a-col :xs="24" :sm="24" :md="12" class="d-flex justify-content-between align-items-center">
                <div> Varian </div>
              </a-col>
            </a-row>
            <a-row>
              <a-col :xs="10" :sm="10" :md="9" class="d-flex justify-content-between align-items-center">
                <a-button type="primary btn-back" ghost>Jambu Biji</a-button>
              </a-col>
            </a-row>
          </a-col>

          <a-divider />

          <a-row>
            <a-col :xs="10" :sm="10" :md="9" class="d-flex justify-content-between align-items-center">
              <span danger>Unit</span>
            </a-col>
            <a-col :md="12" class="unit-cart d-flex justify-content-end">
              <div class="select-box" style="cursor: pointer">
                <a-dropdown :trigger="['click']">
                  <div class="d-flex justify-content-end align-items-center">
                    <div> {{ unitSelected? `${unitSelected.uom}` : $t('purchase.catalogue.select_unit') }} </div>
                    <div class="icon-unit"> <a-icon type="down" /> </div>
                  </div>
                  <a-menu slot="overlay" @click="handleSelectUom">
                    <a-menu-item v-for="(item) in unitList" :key="item.uom" class="row">
                      <div class="col-4"> {{ item?.uom }} </div>
                      <div class="col-8"> {{ item?.selling_price | currency }} </div>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </div>
            </a-col>
          </a-row>

          <a-divider />

          <a-row>
            <a-col :xs="10" :sm="10" :md="9" class="d-flex justify-content-between align-items-center">
              <span danger>Jumlah</span>
            </a-col>
            <a-col :md="12" class="d-flex justify-content-end jumlah-cart">
              <div @click.prevent="updateQty('min')" style="cursor: pointer"> - </div>
              <div>
                <a-input class="centered-input" :value="qty" @keypress="validateQty($event)" @input="updateQty"
                  @blur="handleNullQty" />
              </div>
              <div class="mr-3" @click.prevent="updateQty('add')" style="cursor: pointer"> + </div>
            </a-col>
          </a-row>

          <div class="pb-3 mb-5">
            <a-col :span="24" class="mt-2" :style="{ cursor: unitSelected && !showDisclaimerQty ? 'pointer' : '' }"
              @click.prevent="handleAddToCart">
              <div class="button-add d-flex justify-content-center pt-2"
                :style="{ backgroundColor: !unitSelected || showDisclaimerQty ? '#E5E5E5 !important' : '#000000 !important', color: 'white', height: '40px' }"
                v-if="!loadingPurchase">
                + {{ $t('purchase.catalogue.cart') }}
              </div>

              <div class="button-add d-flex justify-content-center pt-2"
                :style="{ backgroundColor: !unitSelected ? '#E5E5E5 !important' : '#265C9B !important' }"
                v-if="loadingPurchase">
                <a-icon type="loading" style="color: #FFFFFF" />
              </div>
            </a-col>
          </div>
        </template>
      </b-modal> -->
    </div>
    <ModalPreventAddCart
      :showModal="showNotificationPreventAddCart"
      @emitCloseModal="(e) => showNotificationPreventAddCart = e"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import InnerImageZoom from 'vue-inner-image-zoom'
import Bag from '@/components/Icons/Bag.vue'
import CatalogueListItem from '@/components/Purchase/Catalogue/CatalogueListItem.vue'
import ModalPreventAddCart from '@/components/Purchase/ModalPreventAddCart.vue'

export default {
  components: {
    InnerImageZoom,
    Bag,
    CatalogueListItem,
    ModalPreventAddCart,
  },
  data: function () {
    return {
      showImage: null,
      title: 'Product Detail',
      price: [],
      qty: 1,
      unitSelected: null,
      selectedVariant: {},
      variants: [],
      unitList: [],
      haveButtonAllImages: false,
      showListImage: [],
      isExpandImage: false,
      showDisclaimerQty: false,
      visible: false,
      uom: '',
      listCatalogId: [],
      recomendationListCatalogId: [],
      dataDiscount: [],
      recomendationDataDiscount: [],
      listProductRecomendation: [],
      isLoading: false,
      priceRecomendation: [],
      defaultTabActive: 1,
      listWarehouse: [],
      stock: null,
      isPreorder: false,
      allCategories: [],
      getCategory: null,
      showDescription: false,
      showNotificationPreventAddCart: false,
      selectedWarehouse: undefined,
      isLoyalty: false,
    }
  },
  metaInfo() {
    return {
      title: this.title,
    }
  },
  computed: {
    ...mapGetters('user', ['isGuest']),
    ...mapState('product', {
      product: state => state.productDetail,
      colorOptions: state => state.colorOptions,
      sizeOptions: state => state.sizeOptions,
      flavorOptions: state => state.flavorOptions,
      defaultOptions: state => state.defaultOptions,
      categories: state => state.categories,
      loading: state => state.loading,
    }),
    loadingPurchase() {
      return this.$store.state.purchase.loading
    },
    isMobileView() {
      return this.$store.state.settings.isMobileView
    },
  },
  mounted() {
    this.fetchProductDetail()
  },
  methods: {
    handleShowDescription() {
      this.showDescription = !this.showDescription
    },
    getBaseQtyProduct(catalog_id, whsId) {
      this.$store.dispatch('warehouse/CHECKINVENTORY', {
        channel_id: this.$store.state.user.user_data.channel_id,
        warehouse_id: whsId?.toString(),
        catalog_id: catalog_id,
      })
        .then(({ data }) => {
          this.stock = data[0].sellable < 0 ? 0 : data[0].sellable
          this.isPreorder = data[0]?.pre_order
        })
        .catch((e) => {
          console.error({ e })
          this.stock = null
          this.isPreorder = false
        })
    },
    handleChangeTab (value) {
      this.defaultTabActive = value
    },
    async fetchProducts(warehouse_id) {
      const warehouse_query =
        warehouse_id.length > 1
          ? `warehouse_id=${warehouse_id.join('&warehouse_id=')}`
          : `warehouse_id=${warehouse_id[0]}`
      this.$store
        .dispatch('product/GETPRODUCTLIST', {
          channel_id: this.$store.state.user.user_data.channel_id,
          limit: 5,
          warehouse_query: warehouse_query,
          category: this.product.category,
        })
        .then(async ({ data }) => {
          this.recomendationListCatalogId = data.map(v => { return v.catalogs[0].id })
          if (this.recomendationListCatalogId.length > 0) {
            await this.fetchFlagDiscount('recomendation')
          }
          const product_id = data.length > 0 && data.map((item) => item.id)
          this.listProductRecomendation = data
          let defaultPriceAreaId = this.$store.state.user.user_data.price_area_id
          const isNOO = Boolean(!this.$store.state.user.user_data.seller?.length)
          if (!isNOO) {
            defaultPriceAreaId = this.$store.state.user.user_data.seller.map((obj) => obj.price_area_id).join(',')
          }
          return this.$store.dispatch('price/GETPRICEPRODUCT', {
            id: product_id,
            channel_id: this.$store.state.user.user_data.channel_id,
            price_area_id: defaultPriceAreaId,
            customer_category_id: this.$store.state.user.user_data.customer_category_id,
            limit: 1,
          })
        })
        .then(({ data }) => {
          this.priceRecomendation = data
        })
    },
    calculatePrice(calc_price_sales_discount, percentage_price_sales, selling_price, discount, discount_type) {
      // if (discount) {
      // }
      if (percentage_price_sales !== null) {
        if (calc_price_sales_discount?.toString() === 'before') {
          const price_before = selling_price - (selling_price * (percentage_price_sales / 100))
          if (!discount) {
            let price = price_before - (price_before * (discount / 100))
            if (price < 0) {
              price = 0
            }
            return price
          } else {
            return price_before
          }
        } else if (calc_price_sales_discount?.toString() === 'after') {
          const price_after = selling_price - (selling_price * (discount / 100))
          if (!discount) {
            let price = price_after - (price_after * (percentage_price_sales / 100))
            if (price < 0) {
              price = 0
            }
            return price
          } else {
            return price_after
          }
        } else {
          let price = selling_price - (selling_price * (discount / 100))
          if (price < 0) {
            price = 0
          }
          return price
        }
      } else if (percentage_price_sales === null) {
        if (!discount) {
          return selling_price
        } else {
          let discountValue = 0
          if (discount_type?.value_type === 'fixed') {
            discountValue = discount
          } else {
            discountValue = selling_price * (discount / 100)
            if (discountValue > discount_type?.max_value) {
              discountValue = discount_type?.max_value
            }
          }
          let price = selling_price - discountValue
          if (price < 0) {
            price = 0
          }
          return price
        }
      }
    },
    getDiscountLabel(param) {
      const filter = this.dataDiscount.filter(v => { return v.catalog_id === param.catalogs[0].id })
      if (filter.length > 0) {
        if (filter?.[0]?.promotions?.[0]?.gift?.[0]?.value_type) {
          return filter
        }
        return []
      }
      return []
    },
    backCatalogue() {
      this.$router.push({ path: '/purchase/catalogue' })
    },
    fetchWarehouseId() {
      const queryWarehouseIds = this.$route.query.warehouse_id
      this.$store.dispatch('warehouse/GETWAREHOUSELIST', {
        id: queryWarehouseIds,
      })
        .then(({ data }) => {
          // this.fetchProducts(data.map((item) => item.id))
          this.listWarehouse = data
          if (data.length === 1) {
            this.selectedWarehouse = data.at(0)?.id
          }
        })
    },
    async fetchProductDetail() {
      const queryWarehouseIds = this.$route.query.warehouse_id
      this.$store.dispatch('product/GETPRODUCTDETAIL', {
        product_id: this.$route.params.id,
        channel_id: this.$store.state.user.user_data.channel_id,
        warehouse_id: Array.isArray(queryWarehouseIds) ? queryWarehouseIds[0] : queryWarehouseIds,
      })
        .then(async () => {
          const selected = []

          if (Array.isArray(this.product.catalogs)) {
            this.product.catalogs.forEach(item => {
              if (item.option) {
                const title = []
                let varian = ''
                for (const property in item.option) {
                  varian = property
                  title.push(item.option[property])
                }
                selected.push({ title: title.join(' '), catalog_id: item.id, varian })
              } else {
                const product_title = this.product.title.split(' ')
                const catalog_title = item.title.split(' ')

                // Hanya logic untuk case tidak ada option di catalogs
                // karena setiap catalog_title itu adalah product_title + variantnya.
                // maka dibuatlah logic seperti ini.

                const data = {
                  title: [],
                  catalog_id: item.id,
                }

                catalog_title.forEach((item, index) => {
                  if (item !== product_title[index]) {
                    data.title.push(item)
                  }
                })

                selected.push({ ...data, title: data.title.length ? data.title.join(' ') : item.title })
              }
            })
            this.listCatalogId = this.product.catalogs.map(v => { return v.id })
            if (this.listCatalogId.length > 0) {
              await this.fetchFlagDiscount()
            }
          }

          this.variants = selected
          this.selectedVariant = this.product.catalogs[0]
          this.fetchWarehouseId()
          let defaultPriceAreaId = this.$store.state.user.user_data.price_area_id
          const isNOO = Boolean(!this.$store.state.user.user_data.seller?.length)
          if (!isNOO) {
            defaultPriceAreaId = this.$store.state.user.user_data.seller.map((obj) => obj.price_area_id).join(',')
          }
          return this.$store.dispatch('price/GETPRICEPRODUCT', {
            id: [this.$route.params.id],
            channel_id: this.$store.state.user.user_data.channel_id,
            price_area_id: defaultPriceAreaId,
            customer_category_id: this.$store.state.user.user_data.customer_category_id,
            limit: 9999,
          })
        })
        .then(({ data }) => {
          this.price = data
          this.createUnitList(this.$route.params.id, this.selectedVariant && this.selectedVariant.id)
        })
    },
    async fetchFlagDiscount(type = 'detail') {
      this.$store
        .dispatch('product/GETDISCOUNT', {
          channel_id: this.$store.state.app.channel_id,
          business_id: this.$store.state.app.business_id,
          catalog_ids: type === 'recomendation' ? this.recomendationListCatalogId.toString() : this.listCatalogId.toString(),
          customer_category_id: this.$store.state.user.user_data.customer_category_id,
        })
        .then((response) => {
          if (type === 'recomendation') {
            this.recomendationDataDiscount = response.data
          } else {
            this.dataDiscount = response.data
          }
        })
    },
    createUnitList(product_id, catalog_id) {
      if (!product_id || !catalog_id) {
        return
      }
      if (this.selectedWarehouse) {
        this.getBaseQtyProduct(catalog_id, this.selectedWarehouse)
      }
      const product = this.price.find(item => item.product_id === product_id && item.catalogs.find(value => value.id === catalog_id))
      const price = product?.catalogs?.find(item => item.id === catalog_id)
      const isNOO = Boolean(!this.$store.state.user.user_data.seller?.length)
      if (isNOO) {
        this.unitList = price ? price.price_list : []
      } else {
        const findSelectedPriceArea = this.$store.state.user.user_data.seller.find((obj) => obj.warehouse_id === this.selectedWarehouse)
        const filteredPriceList = price.price_list.filter((obj) => obj.price_area_id === findSelectedPriceArea.price_area_id)
        this.unitList = filteredPriceList?.length > 0 ? filteredPriceList : []
      }
      this.unitSelected = this.unitList && this.unitList[0]
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('id-ID').format(value || 0)}`
    },
    setShowImage(value) {
      this.showImage = value
    },
    updateQty(param) {
      if (!this.stock) {
        return this.$notification.warning({
          message: 'Stok Habis',
        })
      }

      if (param === 'min' && this.qty > 1) {
        this.qty = this.qty - 1
      } else if (param === 'add' && String(this.qty + 1).length <= 4) {
        if (this.qty + 1 > this.stock) {
          this.qty = parseInt(this.stock)
          return this.$notification.warning({
            message: 'Stok Habis',
          })
        }
        this.qty = this.qty + 1
      } else {
        if (String(param.target.value).length <= 4) {
          if (param.target.value === '' || param.target.value === '0') {
            this.showDisclaimerQty = true
            this.qty = ''
          } else {
            this.showDisclaimerQty = false
            if (parseInt(param?.target?.value) > parseInt(this.stock)) {
              this.qty = parseInt(this.stock)
              this.$forceUpdate()
              return this.$notification.warning({
                message: 'Stok Habis',
              })
            }
            this.qty = parseInt(param.target.value)
          }
        }
        this.$forceUpdate()
      }
    },
    handleNullQty(param) {
      if (param.target.value === '' || param.target.value === '0') {
        this.qty = 1
        this.showDisclaimerQty = false
      }
    },
    validateQty(event) {
      const regex = new RegExp(/^[\d]*$/g)
      if (!regex.test(event.key)) {
        event.preventDefault()
      }
    },
    handleSelectUom({ key }) {
      this.unitSelected = this.unitList.find(item => item.uom === key) || null
      this.uom = this.unitSelected.uom
    },
    getLabelPrice({ key }) {
      return this.unitList.find(item => item.uom === key) || null
    },
    handleAddToCart() {
      this.isLoading = true
      if (!this.unitSelected) {
        return this.$notification.warning({
          message: this.$t('purchase.cart.notif_select_unit'),
        })
      }
      if (this.$store.state.account.detailCustomer.is_active) {
        let payload = {
          cart_id: this.$store.state.purchase.cartId,
          item_id: this.selectedVariant.id,
          product_id: this.$route.params.id,
          catalog_sku: this.selectedVariant.sku,
          catalog_title: this.selectedVariant.title,
          // showimg_url: this.selectedVariant.showimg_url,
          showimg_url: this.product.showimg_url,
          qty: this.qty,
          buyer_id: this.$store.state.user.user_data.buyer_id,
          area_id: this.$store.state.user.user_data.area_id,
          uom: this.unitSelected && this.unitSelected.uom,
          price_id: this.unitSelected && this.unitSelected.id,
          item_price: this.unitSelected && this.unitSelected?.selling_price,
          currency: this.unitSelected && this.unitSelected.currency,
          warehouse_id: this.selectedWarehouse,
        }

        if (this.isGuest) {
          payload = {
            ...payload,
            buyer_id: null,
          }
        }

        this.$store.dispatch(this.isGuest ? 'purchase/ADDTOCARTGUEST' : 'purchase/ADDTOCART', {
          ...payload,
          channel_id: this.$store.state.user.user_data.channel_id,
        })
          .then(({ data }) => {
            this.$notification.success({
              message: this.$t('purchase.cart.success'),
              placement: 'bottomRight',
            })
            this.handleGetNewCart()
            this.visible = false
            this.isLoading = false
          })
          .catch((err) => {
            const { data } = err.response
            this.$notification.error({
              message: this.$t('purchase.cart.failed'),
              placement: 'bottomRight',
              description: data && data.message ? data.message : 'Internal Server Error',
            })
            this.visible = false
            this.isLoading = false
          })
      } else {
        this.isLoading = false
        this.showNotificationPreventAddCart = true
      }
    },
    handleChangeVariant(id, item) {
      this.selectedVariant = this.product.catalogs.find(item => item.id === id) || {}
      this.createUnitList(this.$route.params.id, this.selectedVariant && this.selectedVariant.id)
    },
    handleButtonAllImages() {
      if (!this.isExpandImage) {
        this.showListImage = this.product.all_images.map(item => item)
        this.isExpandImage = !this.isExpandImage
        // eslint-disable-next-line
        return
      }

      if (this.isExpandImage) {
        const tmp = []
        for (let i = 0; i < 4; i++) {
          tmp.push(this.product.all_images[i])
        }

        this.showListImage = tmp
        this.isExpandImage = !this.isExpandImage
        // eslint-disable-next-line
        return
      }
    },
    handleGetNewCart () {
      this.$store.dispatch(this.isGuest ? 'purchase/GETCARTGUEST' : 'purchase/GETCART', {
        channel_id: this.$store.state.user.user_data.channel_id,
      })
        .catch((err) => console.error(err))
        .finally(() => {
          this.loading = false
        })
    },
  },
  watch: {
    product: {
      immediate: true,
      deep: true,
      handler: function (value) {
        // if (Array.isArray(value.all_images) && value.all_images.length > 4) {
        //   this.haveButtonAllImages = true
        //   const tmp = []
        //   for (let i = 0; i < 4; i++) {
        //     tmp.push(value.all_images[i])
        //   }

        //   this.showListImage = tmp
        // } else {
        this.haveButtonAllImages = false
        this.isExpandImage = true
        this.showListImage = value?.all_images?.map(item => item)
        this.getCategory = this.allCategories?.find(val => val.id === value?.category)
        // }
      },
    },
    '$route.params.id': {
      immediate: true,
      deep: true,
      handler: function (value) {
        if (value) this.fetchProductDetail()
      },
    },
    categories: {
      immediate: true,
      deep: true,
      handler: function (value) {
        if (value.length) {
          const tempCategories = []
          const flatCategory = (value = []) => {
            value.forEach(category => {
              tempCategories.push(category)
              flatCategory(category.children)
            })
          }
          flatCategory(value)
          this.allCategories = tempCategories
        }
      },
    },
    selectedWarehouse: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.unitSelected = null
          this.qty = 1
          this.createUnitList(this.$route.params.id, this.selectedVariant && this.selectedVariant.id)
        }
      },
    },
  },
}
</script>

<style lang="scss">
.title-detail {
  @media (max-width: 600px) {
    font-size: 18px;
  }
}

.wrapper__product__detail {
  padding: 2rem;

  @media (max-width: 600px) {
    padding: 0;
  }
}

.product-detail {
  .catalog-icon {
    background: #fff !important;
    padding: 7px 12px;
    border-radius: 50%;
  }

  .button-uom-dis {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 9px;
    width: 50px;
    height: 35px;
    background: #F4F4F4;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #767676;
  }

  .button-uom-select {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 9px;
    width: 50px;
    height: 35px;
    background: #FFF1E5;
    border: 1px solid #F5831F;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #F5831F;
  }

  .title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 40px;
    color: var(--biz-base-100);

    @media (max-width: 600px) {
      font-size: 16px;
    }
  }

  .product-content{
    justify-content: center;
    @media (max-width: 600px){
      justify-content: space-between;
    }
  }

  .sku {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: var(--biz-base-20);
  }

  .price {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: var(--biz-brand);
  }

  .label-disc-2 {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 10px;
    height: 24px;
    background: var(--biz-accent-1);
    border-radius: 2.91702px;
    justify-content: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 11.1467px;
    line-height: 15px;
    text-transform: capitalize;
    color: var(--biz-brand-base);
  }

  .variant_option {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 8px;
    gap: 8px;
    width: auto;
    height: 26px;
    background: #FFFFFF;
    border: 1px solid #F3F3F3;
    border-radius: 6px;
    color: var(--biz-base-40);
  }

  .variant_title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--biz-base-40);
  }

  .plus-min-btn {
    cursor: pointer;
    width: 100%;
    text-align: center;
    border: 0.5px solid #585858;
    box-sizing: border-box;
    color: var(--biz-base-100);
  }

  .price-disc-1 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: line-through;
    color: var(--biz-base-20);

    @media (max-width: 576px) {
      font-size: 12px;
      margin-right: 2px;
    }
  }

  .price-disc-2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #ED1C24;

    @media (max-width: 576px) {
      font-size: 12px;
      margin-right: 2px;
    }
  }
  .price-disc-2-v2 {
    font-style: normal;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: line-through;
    text-transform: capitalize;
    color: #ED1C24;

    @media (max-width: 576px) {
      font-size: 12px;
      margin-right: 2px;
    }
  }

  .price-disc-3 {
    font-style: normal;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    text-transform: capitalize;
    color: #FF685E;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2.66667px 4.66667px;
    gap: 3.33px;
    background: rgba(255, 104, 94, 0.2);
    border-radius: 2.78667px;

    @media (max-width: 576px) {
      font-size: 10px;
      padding: 1.66667px 2.66667px;
    }
  }

  .pca-tab-store {
    .ant-tabs-bar {
      background-color: #fff;
      margin-bottom: 0;
      border-bottom-color: transparent;
    }
    .ant-tabs-nav .ant-tabs-tab:hover,
    .ant-tabs-nav .ant-tabs-tab-active {
      color: var(--biz-accent-3);
    }
    .ant-tabs-ink-bar {
      background-color: var(--biz-accent-3);
    }
  }

  .select-box {
    width: 100%;
    height: 33px;
    left: 40px;
    top: 80px;
    padding: .5rem;
    border: 0.5px solid #585858;
    box-sizing: border-box;
    border-radius: 2px !important;
  }

  .button-add {
    width: 100%;
    height: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    background: #265C9B;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .variant {
    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }

    &__option {
      width: auto;
      height: 27px;
      background: #F4F4F4;
      border-radius: 8px;
      padding: .5rem;
      cursor: pointer;
    }

    .active {
      background: rgba(255, 104, 94, 0.05) !important;
      border: 1px solid var(--biz-brand-legacy);
      color: var(--biz-brand-legacy);
    }
  }

  .button-expand-image {
    border: 1px solid #265C9B;
    color: #265C9B;
    padding: 5px;
    width: 50%;
    border-radius: 8px;
    cursor: pointer;
  }

  .button-expand-image:hover {
    background: #265C9B;
    color: #FFFFFF;
  }
}

.--h___200 {
  height: 200px;
  overflow: hidden;

  @media (max-width: 600px) {
    height: 150px;
  }
}

.desc-product {
  color: #000000;
  @media (max-width: 600px) {
    font-size: 12px;
  }
}

.btn-back {
  border-color: #000000 !important;
  color: #000000 !important;
}

.btn-submit {
  background-color: var(--biz-brand-button) !important;
  border-color: var(--biz-brand-button) !important;
}
.btn-submit-disabled {
  background-color: var(--biz-base-20) !important;
  border-color: var(--biz-base-20) !important;
  ;
}

.centered-input {
  text-align: center;
  border: 2px solid black;
  height: 30px !important;
}

.required-qty-details {
  text-align: center;
  font-size: 12px;
  color: #ff0000;
  margin-top: 2px;
}

.product-promo {
  position: absolute;
  background: #FF0000;
  color: white;
  padding: 2px 10px;
  border-radius: 8px;
  font-size: 20px;
  top: 12px;
  right: 12px;
  z-index: 10;
}

.desc-promo {
  color: red;
  font-weight: bold;
}

.unit-cart {
  height: 35px;
  margin-left: 40px;
  border: groove;
  border-radius: 10px;
}

.icon-unit {
  padding-left: 140px;
  padding-right: 10px;
  padding-top: 5px;
}

.jumlah-cart {
  margin-left: 40px;
  border: groove;
  border-radius: 10px;
}

.img-zoom {
  div {
    display: flex;
    justify-content: center;
    width: 500px;
    height: 400px;

    @media (max-width: 600px) {
      justify-content: left;
      width: auto;
      height: auto;
    }

    img {
      max-height: 400px;
      object-fit: contain !important;
    }
  }
}

.img-zoom-v2 {
  div {
    display: flex;
    justify-content: center;
    width: 500px;
    height: 400px;

    @media (max-width: 600px) {
      justify-content: left;
      width: auto;
      height: auto;
    }

    img {
      max-height: 400px;
      object-fit: contain !important;
    }
  }
}
div.btn-show {
  background: transparent;
  color: var(--biz-brand);
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
  }
}
.nama_kategori {
  color: black;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.nama_kategori:hover {
  font-weight: bold;
  text-decoration: underline;
}
</style>
